import Title from "@components/UI-Elements/Typography/Title";
import React from "react";
import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import Card from "../../../components/UI-Elements/Card";
import LinkList, { LinkItem } from "../../../components/UI-Elements/Lists/LinkList";
import List from "../../../components/UI-Elements/Lists/List";
import CategoryTemplate from "../../../templates/category-page";

const pages: LinkItem[] = [
  { href: "https://blockworks.co/the-investors-guide-to-defi-2-0/", title: "The Investor’s Guide to DeFi 2.0" },
  { href: "https://defillama.com/", title: "Defi Lama" },
  { href: "https://www.fidor.de/blog/defi", title: "Fidor Bank: DeFi - Entwicklung, Risiken und Zukunft" },
  { href: "https://www.defipulse.com", title: "DeFi Pulse" },
  { href: "https://dune.xyz/hagaetc/stablecoins", title: "Dune Analytics - Stablecoins Dashboard" },
  { href: "https://blog.cakedefi.com/de/", title: "Cake DeFi Blog" },
];
const coins = ["uniswap", "terra-luna", "aave", "maker", "compound-governance-token"];

// Was ist eine DEX?
const anwendungen = [
  "Stable Coins",
  "Dezentrale Kredite",
  "Dezentrale Zahlungsdienstleistungen",
  "Dezentrale Börsen",
  "Dezentrale Derivate",
];
const title = "DeFi (Decentralized Finance)";

const Page: React.FC = () => {
  return (
    <Layout>
      <SEO keywords={["was ist defi", "defi"]} title="Was ist DeFi?" />
      <CategoryTemplate title={title} coins={coins} coingeckoId="decentralized-finance-defi">
        <div className="grid md:grid-cols-2 gap-4">
          <section className="py-4">
            <div>
              <Title level={2}>📄 Informationen</Title>
            </div>
            <Card>
              <LinkList links={pages.map((page) => ({ ...page, external: true }))} />
            </Card>
          </section>
          <section className="py-4">
            <div className="grid gap-4">
              <Card title="Was ist DeFi?">
                <p className="pb-2">
                  Der Begriff DeFi steht für Decentralized Finance bzw. Dezentrale Finanzdienstleistungen und ist die Bezeichnung für
                  Finanzdienstleistungen, welche auf Blockchain Technologien basieren. Diese werden deshalb nicht durch eine zentrale
                  Autorität kontrolliert oder gesteuert.
                </p>
                <Title level={4}>Anwendungen</Title>
                <List items={anwendungen} />
              </Card>
            </div>
          </section>
        </div>
      </CategoryTemplate>
    </Layout>
  );
};

export default Page;
